import './Comparison.css'
import Stability from '../NetworkStatusSummaryPage/Stability'
import Jitter from '../NetworkStatusSummaryPage/Jitter'
import Volatility from '../NetworkStatusSummaryPage/Volatility'
import TcpBandwidthDownload from '../BandwidthPage/TcpBandwidthDownload'
import TcpBandwidthUpload from '../BandwidthPage/TcpBandwidthUpload'
import ConnectivityHealth from '../NetworkStatusSummaryPage/ConnectivityHealth'
import LatencyHistoryChart from '../NetworkStatusSummaryPage/LatencyHistoryChart'
import CombinedBarChart from './CombinedBarChart'
import { Grid, Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { TimePeriodSelect, RefreshFrequencySelect } from '../TimeUtils'
import { useTranslation } from 'react-i18next'


const Comparison = () => {
  const [timePeriod, setTimePeriod] = useState('5min');
  const [refreshFrequency, setRefreshFrequency] = useState('5seconds');
  const [agentInput, setAgentInput] = useState('');
  const [agentData, setAgentData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // const intervalIdRef = useRef(null);
  const { t } = useTranslation();

  const fetchAgentData = async (agentId) => {
    console.log(`Fetching data for agent ${agentId}`);
    return {
      connectivityHealth: 'Warning',
      stability: Math.random() * 100,
      volatility: Math.random() * 10,
      jitter: Math.random() * 5,
      tcpDownload: Math.random() * 300,
      tcpUpload: Math.random() * 200,
    };
  };

  const handleFetchData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const agentIds = agentInput.split(',').map(id => id.trim()).filter(id => id !== '');
    const newAgentData = {};
    for (const agentId of agentIds) {
      newAgentData[agentId] = await fetchAgentData(agentId);
    }
    setAgentData(newAgentData);
    setIsLoading(false);
  };

  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
  };

  const handleRefreshFrequencyChange = (e) => {
    setRefreshFrequency(e.target.value);
  };

  const handleAgentInputChange = (e) => {
    setAgentInput(e.target.value);
  };

  return (
    <Box className="comparison">
      <Typography variant="h3" className="page-heading">{t('agentComparison')}</Typography>
      <Box className="select-container">
        <TimePeriodSelect
          value={timePeriod}
          onChange={handleTimePeriodChange}
        />
        <RefreshFrequencySelect
          value={refreshFrequency}
          onChange={handleRefreshFrequencyChange}
        />
      </Box>
      <form onSubmit={handleFetchData}>
        <TextField
          variant="outlined"
          value={agentInput}
          onChange={handleAgentInputChange}
          placeholder="Enter agent IDs (1,2,3, ...)"
          className="white-background-input agent-id-field"
          style={{ marginRight: '10px', marginBottom: '20px', minWidth: '200px' }}
        />
        <Button variant="contained" color="primary" type="submit" disabled={isLoading} className='search_comparison_btn'>
          {isLoading ? <CircularProgress size={24} /> : 'Search'}
        </Button>
      </form>
      {Object.entries(agentData).map(([agentId, data]) => (
        <Grid container spacing={1} key={agentId} style={{ marginBottom: '20px' }}>
          <Grid item xs={12}>
            <Typography variant="h5" className='AgentComparisonID'>Agent {agentId}</Typography>
          </Grid>
          <Grid item xs={2} className='compconnect'>
            <ConnectivityHealth timePeriod={timePeriod} agentId={agentId} refreshFrequency={refreshFrequency} />
            <Stability timePeriod={timePeriod} agentId={agentId} refreshFrequency={refreshFrequency} />
          </Grid>
          <Grid item xs={2} className='compVol'>
            <Volatility timePeriod={timePeriod} agentId={agentId} refreshFrequency={refreshFrequency} />
            <Jitter timePeriod={timePeriod} agentId={agentId} refreshFrequency={refreshFrequency} />
          </Grid>
          <Grid item xs={2} className='compItem'>
            <TcpBandwidthDownload timePeriod={timePeriod} agentId={agentId} refreshFrequency={refreshFrequency} />
            <TcpBandwidthUpload timePeriod={timePeriod} agentId={agentId} refreshFrequency={refreshFrequency} />
          </Grid>
          <Grid item xs={3} className='compItem'>
            <CombinedBarChart timePeriod={timePeriod} agentId={agentId} refreshFrequency={refreshFrequency} />
          </Grid>
          <Grid item xs={3} className='compItem'>
            <LatencyHistoryChart timePeriod={timePeriod} agentId={agentId} refreshFrequency={refreshFrequency} />
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default Comparison;