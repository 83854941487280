import './DetailedLatency.css'
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import TcpLatencyChart from './TcpLatencyChart'
import UdpLatencyChart from './UdpLatencyChart'
import HttpLatencyChart from './HttpLatencyChart'
import HttpsLatencyChart from './HttpsLatencyChart'
import TwampLatencyChart from './TwampLatencyChart'
import IcmpLatencyChart from './IcmpLatencyChart'
import ProtocolHttp from './ProtocolHttp'
import ProtocolHttps from './ProtocolHttps'
import ProtocolIcmp from './ProtocolIcmp'
import ProtocolTcp from './ProtocolTcp'
import ProtocolTwamp from './ProtocolTwamp'
import ProtocolUdp from './ProtocolUdp'
import Jitter from '../NetworkStatusSummaryPage/Jitter'
// import JitterHistoryChart from './JitterHistoryChart'
import { TimePeriodSelect, RefreshFrequencySelect } from '../TimeUtils';
import { AgentIdInput } from '../agentIdManager';
import { useAgent } from '../../../context/AgentProvider';
import { useTranslation } from 'react-i18next';

const DetailedLatency = () => {
  const [timePeriod, setTimePeriod] = useState('5min');
  const [refreshFrequency, setRefreshFrequency] = useState('5seconds');
  const { selectedAgent, getAgentId, updateAgentId } = useAgent();
  const [currentAgentId, setCurrentAgentId] = useState(getAgentId());
  // const intervalIdRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    // Initialize currentAgentId with the value from localStorage
    setCurrentAgentId(getAgentId());
  }, [selectedAgent]);

  const handleFetchData = (agentId) => {
    setCurrentAgentId(agentId);
    updateAgentId(agentId);
  };

  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
  };

  const handleRefreshFrequencyChange = (e) => {
    setRefreshFrequency(e.target.value);
  };

  return (
    <Box className="detailed-latency">
      <Typography variant="h3" className="page-heading">{t('detailedLatency')}</Typography>
      <Box className="select-container">
        <TimePeriodSelect
          value={timePeriod}
          onChange={handleTimePeriodChange}
        />
        <RefreshFrequencySelect
          value={refreshFrequency}
          onChange={handleRefreshFrequencyChange}
        />
      </Box>

      <AgentIdInput onSubmit={handleFetchData} />

      <Grid container spacing={2} className='detailed_latency_container'>
        <Grid item xs={10} className = 'detailed_lat_chart'>
          <HttpLatencyChart timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={2}>
          <ProtocolHttp timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={10}>
          <HttpsLatencyChart timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={2}>
          <ProtocolHttps timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={10}>
          <TcpLatencyChart timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={2}>
          <ProtocolTcp timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={10}>
          <UdpLatencyChart timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={2}>
          <ProtocolUdp timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={10}>
          <IcmpLatencyChart timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={2}>
          <ProtocolIcmp timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={10}>
          <TwampLatencyChart timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={2}>
          <ProtocolTwamp timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>

      </Grid>
    </Box>
  );
};

export default DetailedLatency;


        // <Grid item xs={10}>
        //   <JitterHistoryChart timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        // </Grid>
        // <Grid item xs={2}>
        //   <Jitter timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        // </Grid>
