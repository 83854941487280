import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom';
import Homepage from './Homepage';
import Training from './components/account/Training';
import NetworkStatusSummary from './components/monitoring/NetworkStatusSummaryPage/NetworkStatusSummary';
import DetailedLatency from './components/monitoring/DetailedLatencyPage/DetailedLatency';
import JitterDisplay from './components/monitoring/JitterPage/JitterDisplay';
import LatencyForecast from './components/monitoring/LatencyForecastPage/LatencyForecast';
import Heatmap from './components/monitoring/HeatmapPage/Heatmap';
import Throughput from './components/monitoring/BandwidthPage/Throughput';
import Lifbe from './components/monitoring/LifbePage/Lifbe';
import Comparison from './components/monitoring/ComparisonPage/Comparison';
//import AiRecommendations from './components/monitoring/AiRecommendations';
import AgentForm from './components/account/AgentForm';
import PublicReflectors from './components/account/PublicReflectors';
import ChatBot from './components/monitoring/ChatBotPage/ChatBot';
import ReflectorForm from './components/account/ReflectorForm';
import TerminalComponent from './components/monitoring/TerminalPage/Terminal';
import TerminalID from './components/monitoring/TerminalPage/TerminalID';
import Team from './components/account/Team';
import Report from './components/monitoring/ReportPage/Report';
import Settings from './components/account/Settings';
import Projects from './components/monitoring/Projects';
import MobileApp from './components/monitoring/MobileAppPage/MobileApp';
import Account from './components/account/Account';
import Registration from './components/landing/Registration';
import Login from './components/landing/Login';
import ChangeSubscription from './components/account/ChangeSubscription';
import LicenseSelector from './components/landing/licenseSelector';
import LicenseGate from './components/landing/licenseGate';
import StripeSuccessHandler from './components/landing/stripeSuccessHandler';
import ProtectedRoute from './components/landing/ProtectedRoute';
import CreatePassword from './components/account/CreatePassword';
import ForgotPassword from './components/landing/forgotPassword';
import RefreshToken from './components/landing/Refresh';
import useAuth from './hooks/useAuth';
import './App.css';
import { useTranslation } from 'react-i18next';
import { FaUserFriends, FaUserEdit, FaFileContract, FaCog, FaUserGraduate, FaBook, FaCube, FaCubes, FaMapMarkedAlt, FaChartLine, FaRobot, FaRuler, FaHome,FaSignOutAlt, FaSun, FaMoon   } from "react-icons/fa";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { MdSsidChart } from "react-icons/md";
import { TbBrightnessFilled } from "react-icons/tb";
import axios from 'axios';


function App() {

  const navigate = useNavigate();
  const location = useLocation(); // Get the current route
  const [userName, setUserName] = useState('');
  const { auth, setAuth } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(auth?.AuthToken);
  const isLanding = location.pathname === '/login'
    || location.pathname === '/registration'
    || location.pathname === '/license-selector'
    || location.pathname === '/change-sub'
    || location.pathname === '/forgot-password'
    || /^\/(new-password|new-password)\/[^/]+$/.test(location.pathname);
  useEffect(() => {
    setIsLoggedIn(!!auth?.AuthToken);
    fetchUserName();
  }, [location]);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [showLanguages, setShowLanguages] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  
  const [expandedSections, setExpandedSections] = useState({
    accountManagement: false,
    dashboard: false,
    analytics: false,
    configurationManagement: false,
  });
  


  const fetchUserName = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/account`, {
        headers: {
          'Authorization': `Bearer ${auth?.AuthToken}`,
        },
      });
      if (response.data) {
        setUserName(`${response.data.firstName} ${response.data.lastName}`);
      }
    } catch (error) {
      console.error('Error fetching user name:', error);
    }
  };

  // Conditionally render the nav only if not on a landing page
  const showNav = !(isLanding);

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };
  
  const handleLogout = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/user/logout`, {}, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/sjon',
        }
      });

      if (response.status === 204 || response.status === 200) {
        // Clear auth state and redirect user
        setAuth({});
        setIsLoggedIn(false);
        navigate('/');
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div>
   {/* Hamburger Menu Button */}
   <button
    className="hamburger-menu"
    onClick={() => setIsMenuOpen(!isMenuOpen)}
  >
    ☰
  </button>
      {showNav  && (
        
        <nav className={!isMenuOpen ? '' : 'removeNav'} >
          
          <div className="nav-content">
         
            {/* Homepage Link */}
            <Link to={'/'} className='homepagebtn'> 
              <div className="NavLogoContainer">
                <div className="NavLogoText">LatenceTech</div>
                <img src={`${process.env.PUBLIC_URL}/favicon_logo.png`} alt="LatenceTech Logo" className="LogoForNav" />
              </div> 
            </Link>
    
            {/* Account Management Section */}
            <div className="nav-section">
            <h1
              onClick={() => toggleSection('accountManagement')} className="nav-header">
              <span className={`arrow ${expandedSections.accountManagement ? 'expanded' : ''}`}>
                ▶
              </span>
              {t('accountManagement')}
            </h1>

              {expandedSections.accountManagement && (
                <ul className="nav-links">
                  <li><Link to="/team"><FaUserFriends /> {t('team')}</Link></li>
                  <li><Link to="/account"><FaUserEdit /> {t('myAccount')}</Link></li>
                  <li><Link to="/training"><FaUserGraduate /> {t('trainingVideos')}</Link></li>
                  <li>
                    <a href="https://online.latence.ca/docs" target="_blank" rel="noopener noreferrer">
                      <FaBook /> {t('documentation')}
                    </a>
                  </li>
                </ul>
              )}
            </div>
  
            {/* Dashboard Section */}
            <div className="nav-section">
            <h1
              onClick={() => toggleSection('dashboard')} className="nav-header">
              <span className={`arrow ${expandedSections.dashboard ? 'expanded' : ''}`}>
                ▶
              </span>
              {t('dashboard')}
            </h1>
              {expandedSections.dashboard && (
                <ul className="nav-links">
                  <li><Link to="/network-summary"><FaCube /> {t('networkStatusSummary')}</Link></li>
                  <li><Link to="/comparison"><FaCubes /> {t('compareAgents')}</Link></li>
                  <li><Link to="/detailed-latency"><FaChartLine /> {t('detailedLatency')}</Link></li>
                  <li><Link to="/jitter"><MdSsidChart /> {t('jitterDisplay')}</Link></li>
                  <li><Link to="/forecast"><FaMagnifyingGlass /> {t('forecast')}</Link></li>
                  <li><Link to="/throughput"><FaRuler /> {t('throughput')}</Link></li>
                  <li><Link to="/lifbe"><FaRuler /> {t('lifbe')}</Link></li>
                </ul>
              )}
            </div>
  
            {/* Analytics Section */}
            <div className="nav-section">
            <h1
              onClick={() => toggleSection('analytics')} className="nav-header">
              <span className={`arrow ${expandedSections.analytics ? 'expanded' : ''}`}>
                ▶
              </span>
              {t('analytics')}
            </h1>
              {expandedSections.analytics && (
                <ul className="nav-links">
                  <li><Link to="/reports"><FaFileContract /> {t('dataReports')}</Link></li>
                </ul>
              )}
            </div>
  
            {/* Configuration Management Section */}
            <div className="nav-section">
            <h1
              onClick={() => toggleSection('configurationManagement')} className="nav-header">
              <span className={`arrow ${expandedSections.configurationManagement ? 'expanded' : ''}`}>
                ▶
              </span>
              {t('configurationManagement')}
            </h1>
              {expandedSections.configurationManagement && (
                <ul className="nav-links">
                  <li><Link to="/config-agent">{t('configureAgents')}</Link></li>
                  <li><Link to="/config-ref">{t('configureReflectors')}</Link></li>
                  <li><Link to="/public-ref">{t('publicReflectors')}</Link></li>
                </ul>
              )}
            </div>
          </div>
  
          {/* Footer Section */}
          {isLoggedIn && (
            <div className="nav-footer">
              {/* Language Button */}
              <div className='feature-box'>
              <div className="language-button-container">
                <button className="language-button" onClick={() => setShowLanguages(!showLanguages)}> 🌐 </button>
                 <div className={`language-options ${showLanguages ? '' : 'hidden'}`}>
                    <button onClick={() => changeLanguage('en')}>EN</button>
                    <button onClick={() => changeLanguage('fr')}>FR</button>
                    <button onClick={() => changeLanguage('es')}>ES</button>
                    <button onClick={() => changeLanguage('swe')}>SWE</button>
                    <button onClick={() => changeLanguage('tc')}>繁</button>
                    <button onClick={() => changeLanguage('ja')}>日本語</button>
                 </div> 
              </div>
            {/* Dark Mode Toggle with Icons */}
      <div
        className={`toggle-container ${darkMode ? 'active' : ''}`}
        onClick={() => {
          setDarkMode(!darkMode);
          document.body.classList.toggle('light-theme', !darkMode);
        }}
      >
        <FaSun className="toggle-icon sun-icon" />
        <div className="toggle-handle"></div>
        <FaMoon className="toggle-icon moon-icon" />
      </div>
      {/* End of Dark Mode Toggle */}
              </div>
              <button onClick={handleLogout} className="logout-button">
              <FaSignOutAlt  className="logout-icon" />
               {t('logout')}
              </button>
              {userName && (
                <div className="user-name">
                  <img src="user.png" alt="User Avatar" className="user-avatar" />
                  &nbsp;&nbsp;{userName}
                </div>
              )}
              
            </div>
          )}
        </nav>
      )}
  
      {/* Main Content */}
      <div className={`app-container ${isLanding ? 'landing-page' : 'main-content'}`}>
        <Routes>
          <Route path="/" element={<ProtectedRoute><Homepage /></ProtectedRoute>} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/license-selector" element={<LicenseSelector />} />
          <Route path='/license-gate' element={<LicenseGate />} />
          <Route path="/stripeSuccessHandler" element={<StripeSuccessHandler />} />
          <Route path='/refresh-token' element={<RefreshToken />} />
          <Route path="/team" element={<ProtectedRoute><Team /></ProtectedRoute>} />
          <Route path="/account" element={<Account />} />
          <Route path='/change-sub' element={<ProtectedRoute><ChangeSubscription /></ProtectedRoute>} />
          <Route path="/reports" element={<ProtectedRoute><Report /></ProtectedRoute>} />
          <Route path="/projects" element={<ProtectedRoute><Projects /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
          <Route path="/training" element={<ProtectedRoute><Training /></ProtectedRoute>} />
          <Route path="/network-summary" element={<ProtectedRoute><NetworkStatusSummary /></ProtectedRoute>} />
          <Route path="/detailed-latency" element={<ProtectedRoute><DetailedLatency /></ProtectedRoute>} />
          <Route path="/jitter" element={<ProtectedRoute><JitterDisplay /></ProtectedRoute>} />
          <Route path="/forecast" element={<ProtectedRoute><LatencyForecast /></ProtectedRoute>} />
          <Route path="/heatmap" element={<ProtectedRoute><Heatmap /></ProtectedRoute>} />
          <Route path="/throughput" element={<ProtectedRoute><Throughput /></ProtectedRoute>} />
          <Route path="/lifbe" element={<ProtectedRoute><Lifbe /></ProtectedRoute>} />
          <Route path="/chatbot" element={<ProtectedRoute><ChatBot /></ProtectedRoute>} />
          <Route path="/comparison" element={<ProtectedRoute><Comparison /></ProtectedRoute>} />
          <Route path="/config-agent" element={<ProtectedRoute><AgentForm /></ProtectedRoute>} />
          <Route path="/config-ref" element={<ProtectedRoute><ReflectorForm /></ProtectedRoute>} />
          <Route path="/public-ref" element={<ProtectedRoute><PublicReflectors /></ProtectedRoute>} />
          <Route path="/connection" element={<ProtectedRoute><TerminalID /></ProtectedRoute>} />
          <Route path="/terminal" element={<ProtectedRoute><TerminalComponent /></ProtectedRoute>} />
          <Route path="/mobile-app" element={<ProtectedRoute><MobileApp /></ProtectedRoute>} />
          <Route path="/new-password/:token" element={<CreatePassword />} />
        </Routes>
      </div>      
    </div>
  );
  
}

export default App;
