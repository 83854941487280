import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './NetworkStatusSummary.css';
import { parseTimeRange, getRefreshInterval } from '../TimeUtils';
import useAuth from '../../../hooks/useAuth';

const Jitter = ({ timePeriod, refreshFrequency, agentId }) => {
  const { auth: { customer_id } } = useAuth();
  const [jitterValue, setJitter] = useState(null);
  const intervalIdRef = useRef(null);
  // const [debugInfo, setDebugInfo] = useState('');
  const { t } = useTranslation();

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const fetchJitter = async () => {
    const jitterQuery = `
    import "math"
    import "regexp"
    
    import "math"
    import "regexp"
    
    data = from(bucket: "LatenceTech")
      |> range(start: -${parseTimeRange(timePeriod)})
      |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
      |> filter(fn: (r) => 
          r["_measurement"] == "twamp_result" or 
          r["_measurement"] == "icmp_result" or 
          r["_measurement"] == "udp_result" or 
          r["_measurement"] == "tcp_result" or 
          r["_measurement"] == "http_result" or 
          r["_measurement"] == "https_result"
        )
      |> filter(fn: (r) => r["_field"] == "delay")
      |> keep(columns: ["_time", "_value", "_measurement"])
    
    diff = data
      |> difference()
    
    absdiff = diff
      |> map(fn: (r) => ({ r with _value: math.abs(x: r._value) }))
    
    meanabsdiff = absdiff
      |> mean()
      |> set(key: "for_pivot", value: "0")
      |> set(key: "_field", value: "meanabsdiff")
    
    meandata = data
      |> mean()
      |> set(key: "for_pivot", value: "0")
      |> set(key: "_field", value: "meandata")
    
    union(tables: [meanabsdiff, meandata])
      |> pivot(rowKey: ["for_pivot"], columnKey: ["_field"], valueColumn: "_value")
      |> map(fn: (r) => ({ r with _value: (r.meanabsdiff / r.meandata) * 100.0}))
      |> group()
      |> mean()
      |> keep(columns: ["_value"])`;
//|> aggregateWindow(every: ${parseTimeRange(refreshFrequency)}, fn: mean, createEmpty: false)
    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: HEADERS,
        body: jitterQuery
      });
      const csvData = await response.text();

      if (response.ok) {
        const lines = csvData.trim().split('\n');
        const valueLine = lines.find(line => line.includes('_result'));
        const value = valueLine.split(',')[3];
        const floatValue = parseFloat(value);
        const finalValue = parseFloat(floatValue.toFixed(2));
        setJitter(finalValue);
        return finalValue;

      } else {
        console.error('Failed to fetch data:', response.status);
        return 0;
      }
    } catch (error) {
      console.error('Error fetching jitter:', error);
      return 0;
    }
  };


  useEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    fetchJitter();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(() => {
      fetchJitter();
    }, refreshInterval);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [agentId, refreshFrequency, timePeriod]);


  return (
    <Box className="network-status-summary">
      <Paper className="network-status-summary paper">
        <Typography variant="h5">{t('jitter')}</Typography>
        <Typography variant="h4" style={{fontWeight: 'bold', fontSize: '4.5vh'}}>{jitterValue}%</Typography>
      </Paper>
    </Box>
  );
};

export default Jitter;
