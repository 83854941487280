import React, { useState, useEffect } from 'react';
import { TextField, Button, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import { useAgent } from '../../context/AgentProvider';
import { FaAngleDown } from "react-icons/fa"

export const AgentIdInput = ({ onSubmit, className }) => {
  const [agentList, setAgentList] = useState({});
  const { selectedAgent, updateAgentId } = useAgent();
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const { auth } = useAuth();
  const getAgentList = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/userGroups/agents`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${auth?.AuthToken}`,
        'Content-Type': 'application/json'
      },
    });

    // Check for non-200 status codes
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    const sortedAgentIDs = data.agentIDs.sort((a, b) => a - b);
    setAgentList(sortedAgentIDs);

    if (!selectedAgent) {
      updateAgentId(sortedAgentIDs[0]);
    }
  }

  useEffect(() => {
    getAgentList();
  }, []);

  // Handle the dropdown click to open the menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the dropdown
  const handleClose = () => {
    setAnchorEl(null);
  };

  // When an agent is selected
  const handleAgentSelect = (agent) => {
    updateAgentId(agent);
    setAnchorEl(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(selectedAgent); // Pass the selected agent back to the parent component
  };


  return (
    <form onSubmit={handleSubmit} className={className}>
      <TextField
        variant="outlined"
        value={selectedAgent} // Show the selected agent
        className="white-background-input agent-id-field"
        style={{ marginRight: '10px', marginBottom: '20px' }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <button
              onClick={handleClick}
            ><FaAngleDown /></button>
          )
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Array.isArray(agentList) && agentList.map((agent) => (
          <MenuItem key={agent} onClick={() => handleAgentSelect(agent)}>
            {agent}
          </MenuItem>
        ))}

      </Menu>
      {/* <button className='agentButton' type="submit">
        {t('go')}
      </button> */}
    </form>
  );
};

//   return (
//     <form onSubmit={handleSubmit}>
//       <TextField
//         variant="outlined"
//         value={localAgentId}
//         onChange={handleChange}
//         className="white-background-input agent-id-field"
//         style={{ marginRight: '10px', marginBottom: '20px' }}
//       />
//       <Button variant="contained" color="primary" type="submit">
//         {t('search')}
//       </Button>
//     </form>
//   );
// };


// agentIdManager.js
// import React, { useState, useEffect } from 'react';
// import { TextField, Button, Menu, MenuItem } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// const LOCAL_STORAGE_KEY = 'currentAgentId';
// const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
// const HEADERS = {
//   'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}` ,
//   'Accept': 'application/csv',
//   'Content-type': 'application/vnd.flux'
// };

// export const getAgentId = () => localStorage.getItem(LOCAL_STORAGE_KEY) || '1';

// export const setAgentId = (newAgentId) => {
//   localStorage.setItem(LOCAL_STORAGE_KEY, newAgentId);
// };

// export const AgentIdInput = ({ onSubmit }) => {
//   const [localAgentId, setLocalAgentId] = useState(getAgentId());
//   const [availableAgents, setAvailableAgents] = useState([]);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const { t } = useTranslation();

//   useEffect(() => {
//     fetchAvailableAgents();
//   }, []);

//   const fetchAvailableAgents = async () => {
//     const query = `import "influxdata/influxdb/schema"

// schema.tagValues(bucket: "LatenceTech", tag: "agentID")`;

//     try {
//       const response = await fetch(API_URL, {
//         method: 'POST',
//         headers: HEADERS,
//         body: query
//       });

//       if (response.ok) {
//         const csvData = await response.text();
//         const lines = csvData.trim().split('\n');
//         const agents = lines.slice(1).map(line => line.split(',')[3]);
//         setAvailableAgents(agents);
//       } else {
//         console.error(`Error fetching available agents: ${response.status} ${response.statusText}`);
//       }
//     } catch (error) {
//       console.error(`Error fetching available agents:`, error);
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setAgentId(localAgentId);
//     onSubmit(localAgentId);
//   };

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const handleAgentSelect = (agent) => {
//     setLocalAgentId(agent);
//     setAgentId(agent);
//     handleClose();
//   };