import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { parseTimeRange, getRefreshInterval } from '../TimeUtils';
import { useTranslation } from 'react-i18next';
import './NetworkStatusSummary.css';
import useAuth from '../../../hooks/useAuth';

const ConnectivityHealth = ({ timePeriod, agentId, refreshFrequency }) => {
  const [data, setData] = useState({
    stability: null,
    expectedStability: null,
    expectedLatency: null,
    applicativeLatency: null,
    connectivityHealth: 'Loading...'
  });
  const intervalIdRef = useRef(null);
  const { t } = useTranslation();
  const { auth: { customer_id } } = useAuth();

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const getStatusClass = useCallback((status) => {
    switch (status) {
      case 'Good': return 'good';
      case 'Warning': return 'warning-health';
      case 'Need Attention': return 'need-attention';
      default: return '';
    }
  }, []);

  const fetchStability = useCallback(async () => {
    const stabilityQuery = `
    import "array"
    import "csv"
    import "regexp"

    // --------------------------------------------------------------------
// Helpers

getValue = (tables=<-) => (tables |> findRecord(fn: (key) => true, idx: 0))._value

tempData =
"
_value
0
"  

tempt = csv.from(csv: tempData, mode: "raw")
  |> map(fn: (r) => ({r with _value: int(v: r._value)}))


// --------------------------------------------------------------------
//  Mean and stddev

data = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> filter(fn: (r) => (r["_measurement"] == "twamp_result" or r["_measurement"] == "icmp_result" or r["_measurement"] == "udp_result" or r["_measurement"] == "tcp_result" or r["_measurement"] == "http_result" or r["_measurement"] == "https_result") )
  |> filter(fn: (r) => r["_field"] == "delay" )
  |> keep(columns: ["_time", "_value"])

stddev = data |> stddev(column: "_value", mode: "population") |> getValue()

mean = data |> mean(column: "_value") |> getValue()
  

// --------------------------------------------------------------------
// HTTPS

httpsEventCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "anomaly")
  |> filter(fn: (r) => r["_field"] == "protocolCount")
  |> filter(fn: (r) => (r["protocols"] == "https") )
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

httpsEventCount = union(tables:[httpsEventCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

httpsDropCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "https_drop")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

httpsDropCount = union(tables:[httpsDropCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

httpsValueCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "https_result")
  |> filter(fn: (r) => r["_field"] == "delay")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> keep(columns: ["_value"])
  |> count()

httpsValueCount = union(tables:[httpsValueCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

httpsSampleCount = httpsDropCount + httpsValueCount


// --------------------------------------------------------------------
// HTTP

httpEventCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "anomaly")
  |> filter(fn: (r) => r["_field"] == "protocolCount")
  |> filter(fn: (r) => (r["protocols"] == "http") )
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

httpEventCount = union(tables:[httpEventCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()


httpDropCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "http_drop")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

httpDropCount = union(tables:[httpDropCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

httpValueCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "http_result")
  |> filter(fn: (r) => r["_field"] == "delay")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> keep(columns: ["_value"])
  |> count()

httpValueCount = union(tables:[httpValueCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

httpSampleCount = httpDropCount + httpValueCount


// --------------------------------------------------------------------
// TCP

tcpEventCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "anomaly")
  |> filter(fn: (r) => r["_field"] == "protocolCount")
  |> filter(fn: (r) => (r["protocols"] == "tcp") )
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

tcpEventCount = union(tables:[tcpEventCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

tcpDropCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "tcp_drop")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

tcpDropCount = union(tables:[tcpDropCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

tcpValueCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "tcp_result")
  |> filter(fn: (r) => r["_field"] == "delay")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> keep(columns: ["_value"])
  |> count()

tcpValueCount = union(tables:[tcpValueCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

tcpSampleCount = tcpDropCount + tcpValueCount


// --------------------------------------------------------------------
// UDP

udpEventCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "anomaly")
  |> filter(fn: (r) => r["_field"] == "protocolCount")
  |> filter(fn: (r) => (r["protocols"] == "udp") )
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

udpEventCount = union(tables:[udpEventCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

udpDropCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "udp_drop")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

udpDropCount = union(tables:[udpDropCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

udpValueCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "udp_result")
  |> filter(fn: (r) => r["_field"] == "delay")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> keep(columns: ["_value"])
  |> count()

udpValueCount = union(tables:[udpValueCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

udpSampleCount = udpDropCount + udpValueCount


// --------------------------------------------------------------------
// ICMP

icmpEventCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "anomaly")
  |> filter(fn: (r) => r["_field"] == "protocolCount")
  |> filter(fn: (r) => (r["protocols"] == "ICMP") )
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

icmpEventCount = union(tables:[icmpEventCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

icmpDropCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "icmp_drop")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

icmpDropCount = union(tables:[icmpDropCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

icmpValueCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "icmp_result")
  |> filter(fn: (r) => r["_field"] == "delay")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> keep(columns: ["_value"])
  |> count()

icmpValueCount = union(tables:[icmpValueCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

icmpSampleCount = icmpDropCount + icmpValueCount


// --------------------------------------------------------------------
// TWAMP

twampEventCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "anomaly")
  |> filter(fn: (r) => r["_field"] == "protocolCount")
  |> filter(fn: (r) => (r["protocols"] == "twamp") )
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

twampEventCount = union(tables:[twampEventCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

twampDropCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "twamp_drop")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

twampDropCount = union(tables:[twampDropCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

twampValueCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "twamp_result")
  |> filter(fn: (r) => r["_field"] == "delay")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> keep(columns: ["_value"])
  |> count()

twampValueCount = union(tables:[twampValueCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

twampSampleCount = twampDropCount + twampValueCount


// --------------------------------------------------------------------
// Active protocols count

protocols = (if httpsSampleCount > 0 then 1.0 else 0.0) +
            (if httpSampleCount > 0 then 1.0 else 0.0) +
            (if tcpSampleCount > 0 then 1.0 else 0.0) +
            (if udpSampleCount > 0 then 1.0 else 0.0) +            
            (if icmpSampleCount > 0 then 1.0 else 0.0) +
            (if twampSampleCount > 0 then 1.0 else 0.0)


// --------------------------------------------------------------------
// Network stability

result = if protocols == 0.0 then 0.0 
                             else 100.0 
                                  * (1.0 - (stddev/mean) ) 
                                  * (1.0 - ( (if httpsSampleCount > 0 then (httpsEventCount/httpsSampleCount) else 0.0) + 
                                             (if httpSampleCount > 0 then (httpEventCount/httpSampleCount) else 0.0) + 
                                             (if tcpSampleCount > 0 then (tcpEventCount/tcpSampleCount) else 0.0) + 
                                             (if udpSampleCount > 0 then (udpEventCount/udpSampleCount) else 0.0) + 
                                             (if icmpSampleCount > 0 then (icmpEventCount/icmpSampleCount) else 0.0) + 
                                             (if twampSampleCount > 0 then (twampEventCount/twampSampleCount) else 0.0)
                                           ) / protocols
                                     ) 
                                  * (1.0 - ( (if httpsSampleCount > 0 then (httpsDropCount/httpsSampleCount) else 0.0) +
                                             (if httpSampleCount > 0 then (httpDropCount/httpSampleCount) else 0.0) +
                                             (if tcpSampleCount > 0 then (tcpDropCount/tcpSampleCount) else 0.0) +
                                             (if udpSampleCount > 0 then (udpDropCount/udpSampleCount) else 0.0) +
                                             (if icmpSampleCount > 0 then (icmpDropCount/icmpSampleCount) else 0.0) +
                                             (if twampSampleCount > 0 then (twampDropCount/twampSampleCount) else 0.0)
                                           ) / protocols
                                    )

stability = if result < 0.0 then 0.0 else result 
array.from(rows: [{_value: stability, _time: 2020-01-01T00:00:00Z,}])`;

    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: HEADERS,
        body: stabilityQuery
      });
      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n');
        const valueLine = lines.find(line => line.includes('_result'));
        if (valueLine) {
          const rawValue = parseFloat(valueLine.split(',')[3]);
          return parseFloat(rawValue.toFixed(2));
        }
      }
      console.error('Failed to fetch stability:', response.status);
      return 0;
    } catch (error) {
      console.error('Error fetching stability:', error);
      return 0;
    }
  }, [agentId, timePeriod]);

  const fetchExpectedStability = useCallback(async () => {
    const expectedStabilityQuery = `
    import "regexp"
    from(bucket: "LatenceTech")
      |> range(start: -${parseTimeRange(timePeriod)})
      |> filter(fn: (r) =>
        r.agentID == "${agentId}" and
          r.customerID == "${customer_id}" and
        r._field == "expectedStability"
      )
      |> last()
      |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")
      |> drop(columns: ["_time", "agentID"])
      |> rename(columns: {"expectedStability": "Expected stability (%)"})
    `;

    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: HEADERS,
        body: expectedStabilityQuery
      });
      const csvData = await response.text();
      if (response.ok) {
        const lines = csvData.trim().split('\n');
        const valueLine = lines.find(line => line.includes('_result'));
        if (valueLine) {
          const columns = valueLine.split(',');
          const value = parseFloat(columns[7]);
          return parseFloat(value.toFixed(1));
        }
      }
      console.error('Failed to fetch expected stability:', response.status);
      return 0;
    } catch (error) {
      console.error('Error fetching expected stability:', error);
      return 0;
    }
  }, [agentId]);

  const fetchExpectedLatency = useCallback(async () => {
    const expectedLatencyQuery = `
    import "regexp"
    from(bucket: "LatenceTech")
      |> range(start: -${parseTimeRange(timePeriod)})
      |> filter(fn: (r) =>
        r._measurement == "metadata_result" and
        r.agentID == "${agentId}" and
          r.customerID == "${customer_id}" and
        r._field == "expectedLatency" 
      )
      |> last()
      |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")
      |> drop(columns: ["_time", "agentID"])
      |> rename(columns: {"expectedLatency": "Expected latency (ms)"})
    `;

    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: HEADERS,
        body: expectedLatencyQuery
      });
      const csvData = await response.text();
      if (response.ok) {
        const lines = csvData.trim().split('\n').map(line => line.trim());
        const valueLine = lines.find(line => line.startsWith(',_result'));
        if (valueLine) {
          const columns = valueLine.split(',');
          const value = parseFloat(columns[7]);
          return parseFloat(value.toFixed(2));
        }
      }
      console.error('Failed to fetch expected latency:', response.status);
      return 0;
    } catch (error) {
      console.error('Error fetching expected latency:', error);
      return 0;
    }
  }, [agentId]);

  const fetchApplicativeLatency = useCallback(async () => {
    const applicativeLatencyQuery = `
    import "regexp"
    application = from(bucket: "LatenceTech")
      |> range(start: -${parseTimeRange(timePeriod)})
      |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
      |> filter(fn: (r) => r["_measurement"] == "http_result" or r["_measurement"] == "https_result" or r["_measurement"] == "tcp_result" or r["_measurement"] == "udp_result")
      |> filter(fn: (r) => r["_field"] == "delay")
      |> keep(columns: ["_measurement", "_time", "_value"])
      |> group(columns: ["_field"])
      |> aggregateWindow(every: ${parseTimeRange(refreshFrequency)}, fn: mean, createEmpty: false)
      |> rename(columns: {_value: "Application"})
      |> keep(columns: ["_time", "Application"])
      |> yield(name: "Application")
    `;

    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: HEADERS,
        body: applicativeLatencyQuery
      });
      const csvData = await response.text();
      if (response.ok) {
        const lines = csvData.trim().split('\n');
        if (lines.length > 1) {
          const lastLine = lines[lines.length - 1];
          const value = parseFloat(lastLine.split(',')[4]);
          return parseFloat(value.toFixed(2));
        }
      }
      console.error('Failed to fetch applicative latency:', response.status);
      return 0;
    } catch (error) {
      console.error('Error fetching applicative latency:', error);
      return 0;
    }
  }, [agentId, timePeriod, refreshFrequency]);

  const calculateConnectivityHealth = useCallback((stabilityValue, expectedStability, expectedLatency, applicativeLatency) => {
    const F = stabilityValue > expectedStability ? 1 : 0;
    const H = stabilityValue < (expectedStability * 0.9) ? 1 : 0;
    const G = expectedLatency > applicativeLatency ? 1 : 0;
    const J = (expectedLatency * 1.2) < applicativeLatency ? 1 : 0;

    const K = (1 * (F + G) + (H + J) * -1);

    switch (K) {
      case 2: return "Good";
      case -2: return "Need Attention";
      case -1:
      case 0:
      case 1: return "Warning";
      default: return `Unexpected K value: ${K}`;
    }
  }, []);

  const fetchDataAndUpdateHealth = useCallback(async () => {
    const stability = await fetchStability();
    const expStability = await fetchExpectedStability();
    const expLatency = await fetchExpectedLatency();
    const appLatency = await fetchApplicativeLatency();

    const health = calculateConnectivityHealth(stability, expStability, expLatency, appLatency);

    setData(prevData => ({
      ...prevData,
      stability,
      expectedStability: expStability,
      expectedLatency: expLatency,
      applicativeLatency: appLatency,
      connectivityHealth: health
    }));
  }, [fetchStability, fetchExpectedStability, fetchExpectedLatency, fetchApplicativeLatency, calculateConnectivityHealth]);

  useEffect(() => {
    fetchDataAndUpdateHealth();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(fetchDataAndUpdateHealth, refreshInterval);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [fetchDataAndUpdateHealth, refreshFrequency]);

  return (
    <Box className="network-status-summary">
      <Paper className="network-status-summary paper">
        <Typography variant="h5">{t('connectionHealth')}</Typography>
        <Typography variant="h4" className={getStatusClass(data.connectivityHealth)} style={{fontWeight: 'bold', fontSize: '3.95vh'}}>
          {data.connectivityHealth}
        </Typography>
      </Paper>
    </Box>
  );
};

export default ConnectivityHealth;