import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { useTranslation } from 'react-i18next';
import { parseTimeRange, getRefreshInterval } from '../TimeUtils';
import '../NetworkStatusSummaryPage/NetworkStatusSummary.css';
import useAuth from '../../../hooks/useAuth';

const CombinedBarChart = ({ timePeriod, agentId, refreshFrequency }) => {
  const [chartData, setChartData] = useState([]);
  const intervalIdRef = useRef(null);
  const { t } = useTranslation();
  const { auth } = useAuth();
  const customer_id = auth.customer_id;

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const fetchMsFromInfluxDB = useCallback(async (protocol) => {
    const query = `
      import "regexp"
      from(bucket: "LatenceTech")
        |> range(start: -${parseTimeRange(timePeriod)})
        |> filter(fn: (r) =>
          r._measurement == "${protocol}_result" and
          r._field == "delay" and
          r.agentID == "${agentId}" and
          r.customerID == "${customer_id}"
        )
        |> aggregateWindow(every: ${parseTimeRange(refreshFrequency)}, fn: mean)
        |> keep(columns: ["_time", "_value"])
        |> rename(columns: {"_value": "${protocol.toUpperCase()}"})
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\r\n');
        if (lines.length > 1) {
          const desiredRow = lines[2];
          const value = desiredRow.split(',')[4];
          const floatValue = parseFloat(value);
          return parseFloat(floatValue.toFixed(1));
        }
        return 0;
      } else {
        console.error(`Error response from InfluxDB: ${response.status} ${response.statusText}`);
        return 0;
      }
    } catch (error) {
      console.error(`Error fetching ${protocol} data from InfluxDB:`, error);
      return 0;
    }
  }, [agentId, timePeriod, refreshFrequency]);

  const fetchDataAndUpdate = useCallback(async () => {
    const [httpsMs, httpMs, tcpMs, udpMs, icmpMs, twampMs] = await Promise.all([
      fetchMsFromInfluxDB('https'),
      fetchMsFromInfluxDB('http'),
      fetchMsFromInfluxDB('tcp'),
      fetchMsFromInfluxDB('udp'),
      fetchMsFromInfluxDB('icmp'),
      fetchMsFromInfluxDB('twamp')
    ]);

    setChartData([
      { protocol: 'HTTPS', latency: httpsMs || 0, color: '#9f009f' },
      { protocol: 'HTTP', latency: httpMs || 0, color: '#ffa500' },
      { protocol: 'TCP', latency: tcpMs || 0, color: '#8ab8ff' },
      { protocol: 'UDP', latency: udpMs || 0, color: '#0066d4' },
      { protocol: 'ICMP', latency: icmpMs || 0, color: '#73bf69' },
      { protocol: 'TWAMP', latency: twampMs || 0, color: '#fade2a' },
    ]);
  }, [fetchMsFromInfluxDB]);

  useEffect(() => {
    fetchDataAndUpdate();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(fetchDataAndUpdate, refreshInterval);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [fetchDataAndUpdate, refreshFrequency]);

  return (
    <Box className="network-status-summary">
      <Paper className="network-status-summary paper">
        <Typography variant="h5">{t('latencyByProto')}</Typography>
        <div style={{ height: 300 }}>
          <ResponsiveBar
            data={chartData}
            keys={['latency']}
            indexBy="protocol"
            margin={{ top: 20, right: 60, bottom: 50, left: 60 }}
            padding={0.3}
            layout="horizontal"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={({ data }) => data.color}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Latency (ms)',
              legendPosition: 'middle',
              legendOffset: 40
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="#ffffff"
            legends={[]}
            motionStiffness={90}
            motionDamping={15}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fill: '#E9F4F4',
                    fontSize: 12,
                  },
                },
                legend: {
                  text: {
                    fill: '#E9F4F4',
                    fontSize: 14,
                  },
                },
              },
              grid: {
                line: {
                  stroke: '#444444',
                  strokeWidth: 1,
                  strokeDasharray: '2 4',
                },
              },
            }}
            animate={true}
            label={d => `${d.value} ms`}
            role="application"
            ariaLabel="Latency by protocol bar chart"
          />
        </div>
      </Paper>
    </Box>
  );
};

export default CombinedBarChart;