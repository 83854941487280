import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { parseTimeRange, getRefreshInterval } from '../TimeUtils';
import useAuth from '../../../hooks/useAuth';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import '../LatencyForecastPage/LatencyForecast.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

const LatencyHistoryChart = ({ timePeriod, agentId, refreshFrequency }) => {
  const { auth: { customer_id } } = useAuth();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const intervalIdRef = useRef(null);
  const { t } = useTranslation();

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const fetchApplicationLatency = useCallback(async () => {
    const query = `
      import "regexp"
      from(bucket: "LatenceTech")
        |> range(start: -${parseTimeRange(timePeriod)})
        |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
        |> filter(fn: (r) => r["_measurement"] == "http_result" or r["_measurement"] == "https_result" or r["_measurement"] == "tcp_result" or r["_measurement"] == "udp_result")
        |> filter(fn: (r) => r["_field"] == "delay")
        |> keep(columns: ["_measurement", "_time", "_value"])
        |> aggregateWindow(every: ${parseTimeRange(refreshFrequency)}, fn: mean, createEmpty: false)
        |> rename(columns: {_value: "Application"})
        |> keep(columns: ["_time", "Application"])
        |> yield(name: "Application")
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n').slice(1); // Skip header line
        return lines.map(line => {
          const parts = line.split(',');
          return { time: parts[3], value: parseFloat(parts[4]) };
        });
      } else {
        console.error(`Error fetching application latency data from InfluxDB: ${response.status} ${response.statusText}`);
        return [];
      }
    } catch (error) {
      console.error(`Error fetching application latency data from InfluxDB:`, error);
      return [];
    }
  }, [agentId, timePeriod, refreshFrequency]);

  const fetchNetworkLatency = useCallback(async () => {
    const query = `
      import "regexp"
      from(bucket: "LatenceTech")
        |> range(start: -${parseTimeRange(timePeriod)})
        |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
        |> filter(fn: (r) => r["_measurement"] == "twamp_result" or r["_measurement"] == "icmp_result")
        |> filter(fn: (r) => r["_field"] == "delay")
        |> keep(columns: ["_measurement", "_time", "_value"])
        |> aggregateWindow(every: ${parseTimeRange(refreshFrequency)}, fn: mean, createEmpty: false)
        |> rename(columns: {_value: "Network"})
        |> keep(columns: ["_time", "Network"])
        |> yield(name: "mean")
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n').slice(1); // Skip header line
        return lines.map(line => {
          const parts = line.split(',');
          return { time: parts[3], value: parseFloat(parts[4]) };
        });
      } else {
        console.error(`Error fetching network latency data from InfluxDB: ${response.status} ${response.statusText}`);
        return [];
      }
    } catch (error) {
      console.error(`Error fetching network latency data from InfluxDB:`, error);
      return [];
    }
  }, [agentId, timePeriod, refreshFrequency]);

  const updateChartData = useCallback((appLatency, netLatency) => {
    const labels = [...new Set([...appLatency.map(d => d.time), ...netLatency.map(d => d.time)])].sort();

    setChartData({
      labels,
      datasets: [
        {
          label: `${t('applicationLatency')}`,
          data: labels.map(label => {
            const point = appLatency.find(d => d.time === label);
            return point ? point.value : null;
          }),
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          pointRadius: 1,
          fill: false,
          tension: 0.4,
          cubicInterpolationMode: 'monotone'
        },
        {
          label: `${t('networkLatency')}`,
          data: labels.map(label => {
            const point = netLatency.find(d => d.time === label);
            return point ? point.value : null;
          }),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.2)',
          pointRadius: 1,
          fill: false,
          tension: 0.4,
          cubicInterpolationMode: 'monotone',
        },
      ],
    });
  }, []);

  const fetchDataAndUpdate = useCallback(async () => {
    const [appLatency, netLatency] = await Promise.all([
      fetchApplicationLatency(),
      fetchNetworkLatency()
    ]);
    updateChartData(appLatency, netLatency);
  }, [fetchApplicationLatency, fetchNetworkLatency, updateChartData]);

  useEffect(() => {
    fetchDataAndUpdate();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(fetchDataAndUpdate, refreshInterval);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [fetchDataAndUpdate, refreshFrequency]);

  const chartOptions = {
    layout: {
      padding: {
        bottom: 10,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          displayFormats: {
            second: 'HH:mm:ss',
          },
        },
        ticks: {
          color: '#A3A3A3',
          font: { size: 12 },
        },
        grid: {
          color: 'rgba(163, 163, 163, 0.2)',
          lineWidth: 1,
        },
      },
      y: {
        ticks: {
          color:  '#A3A3A3',
          font: { size: 12 },
        },
        grid: {
          color: 'rgba(163, 163, 163, 0.2)',
          lineWidth: 1,
        },
        title: {
          display: false, // Disable the title to remove the gap
        },
      },
    },
    elements: {
      point: {
        radius: 5,
        hoverRadius: 7,
      },
    },
    plugins: {
      legend: {
        labels: {
          font: { size: 12, family: 'Arial, sans-serif', weight: 'bold' },
          color: '#8D8D8D',
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleFont: { size: 14, family: 'Arial, sans-serif', weight: 'bold' },
        bodyFont: { size: 12, family: 'Arial, sans-serif' },
        bodyColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'rgba(255, 255, 255, 1)',
        padding: 10,
        borderWidth: 1,
        borderColor: 'rgba(255, 255, 255, 0.2)',
      },
    },
  };

  return (
    <Box className="latency-forecast">
      <Paper className="network-status-summary paper latency-history">
        <Typography variant="h5">{t('latencyHistory')} (ms)</Typography>
        <div className="chart-container">
          <Line data={chartData} options={chartOptions} />
        </div>
      </Paper>
    </Box>
  );
};

export default LatencyHistoryChart;