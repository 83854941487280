import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import './NetworkStatusSummary.css';
import { parseTimeRange, getRefreshInterval } from '../TimeUtils';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

const Stability = ({ timePeriod, refreshFrequency, agentId }) => {
  const { auth: { customer_id } } = useAuth();
  const [stabilityValue, setStability] = useState(null);
  const intervalIdRef = useRef(null);
  const [debugInfo, setDebugInfo] = useState('');
  const { t } = useTranslation();

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const getValueClass = (value) => {
    if (value < 50) {
      return 'low';
    } else if (value >= 50 && value <= 75) {
      return 'medium';
    } else if (value >= 75 && value <= 85) {
      return 'medium-high';
    } else {
      return 'high';
    }
  };

  const fetchStability = async () => {
    const stabilityQuery = `
    import "array"
    import "csv"
    import "regexp"

    // --------------------------------------------------------------------
// Helpers

getValue = (tables=<-) => (tables |> findRecord(fn: (key) => true, idx: 0))._value

tempData =
"
_value
0
"  

tempt = csv.from(csv: tempData, mode: "raw")
  |> map(fn: (r) => ({r with _value: int(v: r._value)}))


// --------------------------------------------------------------------
//  Mean and stddev

data = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> filter(fn: (r) => (r["_measurement"] == "twamp_result" or r["_measurement"] == "icmp_result" or r["_measurement"] == "udp_result" or r["_measurement"] == "tcp_result" or r["_measurement"] == "http_result" or r["_measurement"] == "https_result") )
  |> filter(fn: (r) => r["_field"] == "delay" )
  |> keep(columns: ["_time", "_value"])

stddev = data |> stddev(column: "_value", mode: "population") |> getValue()

mean = data |> mean(column: "_value") |> getValue()
  

// --------------------------------------------------------------------
// HTTPS

httpsEventCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "anomaly")
  |> filter(fn: (r) => r["_field"] == "protocolCount")
  |> filter(fn: (r) => (r["protocols"] == "https") )
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

httpsEventCount = union(tables:[httpsEventCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

httpsDropCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "https_drop")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

httpsDropCount = union(tables:[httpsDropCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

httpsValueCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "https_result")
  |> filter(fn: (r) => r["_field"] == "delay")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> keep(columns: ["_value"])
  |> count()

httpsValueCount = union(tables:[httpsValueCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

httpsSampleCount = httpsDropCount + httpsValueCount


// --------------------------------------------------------------------
// HTTP

httpEventCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "anomaly")
  |> filter(fn: (r) => r["_field"] == "protocolCount")
  |> filter(fn: (r) => (r["protocols"] == "http") )
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

httpEventCount = union(tables:[httpEventCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()


httpDropCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "http_drop")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

httpDropCount = union(tables:[httpDropCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

httpValueCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "http_result")
  |> filter(fn: (r) => r["_field"] == "delay")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> keep(columns: ["_value"])
  |> count()

httpValueCount = union(tables:[httpValueCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

httpSampleCount = httpDropCount + httpValueCount


// --------------------------------------------------------------------
// TCP

tcpEventCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "anomaly")
  |> filter(fn: (r) => r["_field"] == "protocolCount")
  |> filter(fn: (r) => (r["protocols"] == "tcp") )
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

tcpEventCount = union(tables:[tcpEventCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

tcpDropCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "tcp_drop")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

tcpDropCount = union(tables:[tcpDropCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

tcpValueCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "tcp_result")
  |> filter(fn: (r) => r["_field"] == "delay")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> keep(columns: ["_value"])
  |> count()

tcpValueCount = union(tables:[tcpValueCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

tcpSampleCount = tcpDropCount + tcpValueCount


// --------------------------------------------------------------------
// UDP

udpEventCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "anomaly")
  |> filter(fn: (r) => r["_field"] == "protocolCount")
  |> filter(fn: (r) => (r["protocols"] == "udp") )
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

udpEventCount = union(tables:[udpEventCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

udpDropCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "udp_drop")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

udpDropCount = union(tables:[udpDropCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

udpValueCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "udp_result")
  |> filter(fn: (r) => r["_field"] == "delay")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> keep(columns: ["_value"])
  |> count()

udpValueCount = union(tables:[udpValueCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

udpSampleCount = udpDropCount + udpValueCount


// --------------------------------------------------------------------
// ICMP

icmpEventCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "anomaly")
  |> filter(fn: (r) => r["_field"] == "protocolCount")
  |> filter(fn: (r) => (r["protocols"] == "ICMP") )
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

icmpEventCount = union(tables:[icmpEventCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

icmpDropCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "icmp_drop")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

icmpDropCount = union(tables:[icmpDropCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

icmpValueCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "icmp_result")
  |> filter(fn: (r) => r["_field"] == "delay")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> keep(columns: ["_value"])
  |> count()

icmpValueCount = union(tables:[icmpValueCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

icmpSampleCount = icmpDropCount + icmpValueCount


// --------------------------------------------------------------------
// TWAMP

twampEventCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "anomaly")
  |> filter(fn: (r) => r["_field"] == "protocolCount")
  |> filter(fn: (r) => (r["protocols"] == "twamp") )
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

twampEventCount = union(tables:[twampEventCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

twampDropCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "twamp_drop")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> count()

twampDropCount = union(tables:[twampDropCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

twampValueCountTemp = from(bucket: "LatenceTech")
  |> range(start: -${parseTimeRange(timePeriod)})
  |> filter(fn: (r) => r["_measurement"] == "twamp_result")
  |> filter(fn: (r) => r["_field"] == "delay")
  |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
  |> keep(columns: ["_value"])
  |> count()

twampValueCount = union(tables:[twampValueCountTemp,tempt])
  |> group()
  |> max(column: "_value")
  |> toFloat()
  |> getValue()

twampSampleCount = twampDropCount + twampValueCount


// --------------------------------------------------------------------
// Active protocols count

protocols = (if httpsSampleCount > 0 then 1.0 else 0.0) +
            (if httpSampleCount > 0 then 1.0 else 0.0) +
            (if tcpSampleCount > 0 then 1.0 else 0.0) +
            (if udpSampleCount > 0 then 1.0 else 0.0) +            
            (if icmpSampleCount > 0 then 1.0 else 0.0) +
            (if twampSampleCount > 0 then 1.0 else 0.0)


// --------------------------------------------------------------------
// Network stability

result = if protocols == 0.0 then 0.0 
                             else 100.0 
                                  * (1.0 - (stddev/mean) ) 
                                  * (1.0 - ( (if httpsSampleCount > 0 then (httpsEventCount/httpsSampleCount) else 0.0) + 
                                             (if httpSampleCount > 0 then (httpEventCount/httpSampleCount) else 0.0) + 
                                             (if tcpSampleCount > 0 then (tcpEventCount/tcpSampleCount) else 0.0) + 
                                             (if udpSampleCount > 0 then (udpEventCount/udpSampleCount) else 0.0) + 
                                             (if icmpSampleCount > 0 then (icmpEventCount/icmpSampleCount) else 0.0) + 
                                             (if twampSampleCount > 0 then (twampEventCount/twampSampleCount) else 0.0)
                                           ) / protocols
                                     ) 
                                  * (1.0 - ( (if httpsSampleCount > 0 then (httpsDropCount/httpsSampleCount) else 0.0) +
                                             (if httpSampleCount > 0 then (httpDropCount/httpSampleCount) else 0.0) +
                                             (if tcpSampleCount > 0 then (tcpDropCount/tcpSampleCount) else 0.0) +
                                             (if udpSampleCount > 0 then (udpDropCount/udpSampleCount) else 0.0) +
                                             (if icmpSampleCount > 0 then (icmpDropCount/icmpSampleCount) else 0.0) +
                                             (if twampSampleCount > 0 then (twampDropCount/twampSampleCount) else 0.0)
                                           ) / protocols
                                    )

stability = if result < 0.0 then 0.0 else result 
array.from(rows: [{_value: stability, _time: 2020-01-01T00:00:00Z,}])`;

    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: HEADERS,
        body: stabilityQuery
      });
      if (response.ok) {
        const csvData = await response.text();
        // console.log('Raw CSV data:', csvData);
        // setDebugInfo(prev => prev + '\nRaw CSV data: ' + csvData);

        const lines = csvData.trim().split('\n');
        const valueLine = lines.find(line => line.includes('_result'));
        if (valueLine) {
          const rawValue = parseFloat(valueLine.split(',')[3]);
          const value = parseFloat(rawValue.toFixed(2));
          setStability(value);
          return value;
        }
        return 0;
      } else {
        console.error('Failed to fetch stability:', response.status);
        return 0;
      }
    } catch (error) {
      console.error('Error fetching stability:', error);
      return 0;
    }
  };


  // useEffect(() => {
  //   const fetchAndUpdateStability = async () => {
  //     await fetchStability();
  //   };

  //   fetchAndUpdateStability();

  //   const refreshInterval = getRefreshInterval(refreshFrequency);
  //   intervalIdRef.current = setInterval(fetchAndUpdateStability, refreshInterval);

  //   return () => {
  //     if (intervalIdRef.current) {
  //       clearInterval(intervalIdRef.current);
  //     }
  //   };
  // }, [agentId, refreshFrequency, timePeriod]);


  useEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    fetchStability();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(() => {
      fetchStability();
    }, refreshInterval);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [agentId, refreshFrequency, timePeriod]);


  return (
    <Box className="network-status-summary">
      <Paper className="network-status-summary paper">
        <Typography variant="h5">{t('networkStability')}</Typography>
        <Typography variant="h4" className={getValueClass(stabilityValue)} style={{fontWeight: 'bold',fontSize: '4.5vh'}}>
          {stabilityValue !== null ? `${stabilityValue}%` : 'Loading...'}
        </Typography>
      </Paper>
    </Box>
  );
};

export default Stability;