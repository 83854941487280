import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { parseTimeRange, getRefreshInterval } from '../TimeUtils';
import useAuth from '../../../hooks/useAuth';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import './Lifbe.css';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

const LifbeHistoryChart = ({ timePeriod, agentId, refreshFrequency }) => {
  const { auth: { customer_id } } = useAuth();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const intervalIdRef = useRef(null);
  const { t } = useTranslation();

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const fetchLifbeData = useCallback(async (direction) => {
    const query = `
      from(bucket: "LatenceTech")
        |> range(start: -${parseTimeRange(timePeriod)})
        |> filter(fn: (r) => r._measurement == "lifbe_result" and r._field == "${direction}MBPS" and r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
        |> keep(columns: ["_time", "_value"])
        |> sort(columns: ["_time"])
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n').slice(1); // Skip header line
        return lines.map(line => {
          const parts = line.split(',');
          return { time: new Date(parts[3]), value: parseFloat(parts[4]) };
        });
      } else {
        console.error(`Error fetching ${direction} LIFBE data from InfluxDB: ${response.status} ${response.statusText}`);
        return [];
      }
    } catch (error) {
      console.error(`Error fetching ${direction} LIFBE data from InfluxDB:`, error);
      return [];
    }
  }, [agentId, timePeriod]);

  const updateChartData = useCallback((downloadData, uploadData) => {
    const labels = [...new Set([...downloadData.map(d => d.time), ...uploadData.map(d => d.time)])].sort();

    setChartData({
      labels,
      datasets: [
        {
          label: 'LIFBE Download Throughput',
          data: labels.map(label => {
            const point = downloadData.find(d => d.time.getTime() === label.getTime());
            return point ? point.value : null;
          }),
          borderColor: 'rgb(143, 59, 184)',
          backgroundColor: 'rgba(143, 59, 184, 0.2)',
          pointRadius: 1,
          fill: true,
          tension: 0.4,
          cubicInterpolationMode: 'monotone'
        },
        {
          label: 'LIFBE Upload Throughput',
          data: labels.map(label => {
            const point = uploadData.find(d => d.time.getTime() === label.getTime());
            return point ? point.value : null;
          }),
          borderColor: 'rgb(222, 182, 242)',
          backgroundColor: 'rgba(222, 182, 242, 0.2)',
          pointRadius: 1,
          fill: true,
          tension: 0.4,
          cubicInterpolationMode: 'monotone'
        }
      ]
    });
  }, []);

  const fetchDataAndUpdate = useCallback(async () => {
    const [downloadData, uploadData] = await Promise.all([
      fetchLifbeData('receiver'),
      fetchLifbeData('sender')
    ]);
    updateChartData(downloadData, uploadData);
  }, [fetchLifbeData, updateChartData]);

  useEffect(() => {
    fetchDataAndUpdate();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(fetchDataAndUpdate, refreshInterval);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [fetchDataAndUpdate, refreshFrequency]);

  const chartOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          displayFormats: {
            second: 'HH:mm:ss',
            minute: 'HH:mm'
          }
        },
        ticks: {
          color:  '#A3A3A3',
          font: { size: 12 },
        },
        grid: {
          color: 'rgba(163, 163, 163, 0.2)',
          lineWidth: 1,
        },
      },
      y: {
        ticks: {
          color:  '#A3A3A3',
          font: { size: 12 },
        },
        grid: {
          color: 'rgba(163, 163, 163, 0.2)',
          lineWidth: 1,
        },
      },
    },
    elements: {
      point: {
        radius: 5,
        hoverRadius: 10,
      },
    },
    plugins: {
      legend: {
        labels: {
          font: { size: 12, family: 'Arial, sans-serif', weight: 'bold' },
          color: '#8D8D8D',
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleFont: { size: 14, family: 'Arial, sans-serif', weight: 'bold' },
        bodyFont: { size: 12, family: 'Arial, sans-serif' },
        bodyColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'rgba(255, 255, 255, 1)',
        padding: 10,
        borderWidth: 1,
        borderColor: 'rgba(255, 255, 255, 0.2)',
      },
    },
  };

  return (
    <Box className="lifbe">
      <Paper className="network-status-summary paper latency-history">
        <Typography variant="h5">{t('lifbeHistory')} (Mb/s)</Typography>
        <div className="chart-container">
          <Line data={chartData} options={chartOptions} />
        </div>
      </Paper>
    </Box>
  );
};

export default LifbeHistoryChart;