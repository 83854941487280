import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { parseTimeRange, getRefreshInterval } from '../TimeUtils';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import './Throughput.css';
import useAuth from '../../../hooks/useAuth';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

const ThroughputChart = ({ timePeriod, agentId, refreshFrequency }) => {
  const { auth: { customer_id } } = useAuth();

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const intervalIdRef = useRef(null);
  const { t } = useTranslation();

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const fetchThroughput = useCallback(async (protocol, direction) => {
    const query = `
      import "regexp"
      from(bucket: "LatenceTech")
        |> range(start: -12h)
        |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
        |> filter(fn: (r) => r["_measurement"] == "iperf_${protocol}_result")
        |> filter(fn: (r) => r["_field"] == "${direction}BPS")
        |> keep(columns: ["_time", "_value"])
        |> aggregateWindow(every: ${parseTimeRange(refreshFrequency)}, fn: mean, createEmpty: false)
        |> rename(columns: {_value: "${protocol.toUpperCase()} ${direction.charAt(0).toUpperCase() + direction.slice(1)} Throughput"})
        |> keep(columns: ["_time", "${protocol.toUpperCase()} ${direction.charAt(0).toUpperCase() + direction.slice(1)} Throughput"])
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n').slice(1); // Skip header line
        return lines.map(line => {
          const parts = line.split(',');
          return { time: new Date(parts[3]), value: parseFloat(parts[4]) };
        });
      } else {
        console.error(`Error fetching ${protocol.toUpperCase()} ${direction} throughput data from InfluxDB: ${response.status} ${response.statusText}`);
        return [];
      }
    } catch (error) {
      console.error(`Error fetching ${protocol.toUpperCase()} ${direction} throughput data from InfluxDB:`, error);
      return [];
    }
  }, [agentId, timePeriod, refreshFrequency]);

  const updateChartData = useCallback((tcpDownload, tcpUpload,
    //udpDownload, udpUpload
  ) => {
    const labels = [...new Set([
      ...tcpDownload.map(d => d.time),
      ...tcpUpload.map(d => d.time),
      //...udpDownload.map(d => d.time),
      //...udpUpload.map(d => d.time)
    ])].sort();

    setChartData({
      labels,
      datasets: [
        {
          label: 'TCP Download',
          data: labels.map(label => {
            const point = tcpDownload.find(d => d.time.getTime() === label.getTime());
            return point ? point.value : null;
          }),
          borderColor: 'rgb(143, 59, 184)',
          backgroundColor: 'rgba(143, 59, 184, 0.2)',
          pointRadius: 1,
          fill: true,
          tension: 0.4,
          cubicInterpolationMode: 'monotone'
        },
        {
          label: 'TCP Upload',
          data: labels.map(label => {
            const point = tcpUpload.find(d => d.time.getTime() === label.getTime());
            return point ? point.value : null;
          }),
          borderColor: 'rgb(222, 182, 242)',
          backgroundColor: 'rgba(222, 182, 242, 0.2)',
          pointRadius: 1,
          fill: true,
          tension: 0.4,
          cubicInterpolationMode: 'monotone'
        },
        // {
        //   label: 'UDP Download',
        //   data: labels.map(label => {
        //     const point = udpDownload.find(d => d.time.getTime() === label.getTime());
        //     return point ? point.value : null;
        //   }),
        //   borderColor: 'rgb(31, 96, 196)',
        //   backgroundColor: 'rgba(31, 96, 196, 0.2)',
        //   pointRadius: 1,
        //   fill: true,
        //   tension: 0.4,
        //   cubicInterpolationMode: 'monotone'
        // },
        // {
        //   label: 'UDP Upload',
        //   data: labels.map(label => {
        //     const point = udpUpload.find(d => d.time.getTime() === label.getTime());
        //     return point ? point.value : null;
        //   }),
        //   borderColor: 'rgb(192, 216, 255)',
        //   backgroundColor: 'rgba(192, 216, 255, 0.2)',
        //   pointRadius: 1,
        //   fill: true,
        //   tension: 0.4,
        //   cubicInterpolationMode: 'monotone'
        // }
      ]
    });
  }, []);

  const fetchDataAndUpdate = useCallback(async () => {
    const [tcpDownload, tcpUpload,
      //udpDownload, udpUpload
    ] = await Promise.all([
      fetchThroughput('tcp', 'receiver'),
      fetchThroughput('tcp', 'sender'),
      //fetchThroughput('udp', 'receiver'),
      //fetchThroughput('udp', 'sender')
    ]);
    updateChartData(tcpDownload, tcpUpload,
      // udpDownload, udpUpload
    );
  }, [fetchThroughput, updateChartData]);

  useEffect(() => {
    fetchDataAndUpdate();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(fetchDataAndUpdate, refreshInterval);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [fetchDataAndUpdate, refreshFrequency]);


  function goodUnit(measure) {
    var throughputMeasure = Math.floor(measure / 1000000);
    return throughputMeasure;
  }

  const chartOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          displayFormats: {
            second: 'HH:mm:ss',
            minute: 'HH:mm'
          }
        },
        ticks: {
          color: '#A3A3A3',
          font: { size: 12 },
        },
        grid: {
          color: 'rgba(163, 163, 163, 0.2)',
          lineWidth: 1,
        },
      },
      y: {
        ticks: {
          color: '#A3A3A3',
          font: { size: 12 },
          callback: function (value, index, values) {
            return value / 1000000 + ' Mb/s';
          }
        },
        grid: {
          color: 'rgba(163, 163, 163, 0.2)',
          lineWidth: 1,
        },
      },
    },
    elements: {
      point: {
        radius: 5,
        hoverRadius: 7,
      },
    },
    plugins: {
      legend: {
        labels: {
          font: { size: 12, family: 'Arial, sans-serif', weight: 'bold' },
          color: '#8D8D8D',
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleFont: { size: 14, family: 'Arial, sans-serif', weight: 'bold' },
        bodyFont: { size: 12, family: 'Arial, sans-serif' },
        bodyColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'rgba(255, 255, 255, 1)',
        padding: 10,
        borderWidth: 1,
        borderColor: 'rgba(255, 255, 255, 0.2)',
      },
    },
  };

  return (
    <Box className="throughput">
      <Paper className="network-status-summary paper latency-history">
        <Typography variant="h5">{t('throughputHistory')}</Typography>
        <div className="chart-container">
          <Line data={chartData} options={chartOptions} />
        </div>
      </Paper>
    </Box>
  );
};

export default ThroughputChart;