import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../DetailedLatencyPage/DetailedLatency.css';
import './NetworkStatusSummary.css';
import { getRefreshInterval } from '../TimeUtils';
import useAuth from '../../../hooks/useAuth';


const AgentMetadata = ({ timePeriod, refreshFrequency, agentId  }) => {
  const { auth: { customer_id } } = useAuth();
  const [metadata, setMetadata] = useState({
    agentName: "Unknown",
    networkName: "Unknown",
    networkType: "Unknown",
    hardware: "Unknown",
    details: "No details available"
  });


  const intervalIdRef = useRef(null);
  const { t } = useTranslation();

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const createQuery = (field) => `
    import "regexp"
    from(bucket: "LatenceTech")
      |> range(start: -1w)
      |> filter(fn: (r) =>
        r._measurement == "metadata_result" and
        r.agentID == "${agentId}" and
          r.customerID == "${customer_id}" and
        r._field == "${field}"
      )
      |> last()
      |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")
      |> drop(columns: ["_time"])
  `;

  const fetchMetadataField = async (field) => {
    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: HEADERS,
        body: createQuery(field)
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n');
        if (lines.length > 1) {
          const columns = lines[1].split(',');
          return columns[columns.length - 1].trim();
        }
      } else {
        console.error(`Failed to fetch ${field}:`, response.status);
      }
    } catch (error) {
      console.error(`Error fetching ${field}:`, error);
    }
    return field === 'details' ? "No details available" : "Unknown";
  };

  

  const fetchAllMetadata = async () => {
    const fields = ['name', 'networkName', 'networkType', 'hardware', 'details'];
    const results = await Promise.all(fields.map(fetchMetadataField));

    setMetadata({
      agentName: results[0],
      networkName: results[1],
      networkType: results[2],
      hardware: results[3],
      details: results[4]
    });

    
  };



  
  useEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    fetchAllMetadata();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(() => {
      fetchAllMetadata();
    }, refreshInterval);

    return () => clearInterval(intervalIdRef.current);
  }, [agentId, refreshFrequency, timePeriod]);

  return (
    <Box className="network-status-summary">
      <Paper className="network-status-summary paper metadata">
        <Typography variant="body2">{t('agentName')}: {metadata.agentName}</Typography>
        <Typography variant="body2">{t('hardware')}: {metadata.hardware}</Typography>
        <Typography variant="body2">{t('networkType')}: {metadata.networkType}</Typography>
        <Typography variant="body2">{t('networkName')}: {metadata.networkName}</Typography>
        <Typography variant="body2">{t('details')}: {metadata.details}</Typography>

      </Paper>
    </Box>
  );
};

export default AgentMetadata;