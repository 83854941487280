import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { parseTimeRange, getRefreshInterval } from '../TimeUtils';
import './NetworkStatusSummary.css';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/useAuth';

const ApplicationBarChart = ({ timePeriod, agentId, refreshFrequency }) => {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const intervalIdRef = useRef(null);
  const { t } = useTranslation();
  const { auth } = useAuth();
  const customer_id = auth.customer_id;

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const fetchMsFromInfluxDB = useCallback(async (protocol) => {
    const query = `
      from(bucket: "LatenceTech")
        |> range(start: -${parseTimeRange(timePeriod)})
        |> filter(fn: (r) =>
          r._measurement == "${protocol}_result" and
          r._field == "delay" and
          r.agentID == "${agentId}" and
          r.customerID == "${customer_id}"
        )
        |> aggregateWindow(every: ${parseTimeRange(refreshFrequency)}, fn: mean, createEmpty: false)
        |> keep(columns: ["_time", "_value"])
        |> sort(columns: ["_time"])
        |> last()
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\r\n').slice(1); // Skip header
        if (lines.length > 0) {
          const values = lines.map(line => {
            const [, , , , value] = line.split(',');
            return parseFloat(value);
          });
          const average = values.reduce((sum, val) => sum + val, 0) / values.length;
          return parseFloat(average.toFixed(2));
        }
      }
      return null;
    } catch (error) {
      console.error(`Error fetching ${protocol} data from InfluxDB:`, error);
      return null;
    }
  }, [agentId, timePeriod]);

  const fetchDataAndUpdate = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const results = await Promise.all([
        fetchMsFromInfluxDB('tcp'),
        fetchMsFromInfluxDB('udp'),
        fetchMsFromInfluxDB('http'),
        fetchMsFromInfluxDB('https')
      ]);

      const newChartData = [
        { protocol: 'TCP', latency: results[0], color: '#8ab8ff' },
        { protocol: 'UDP', latency: results[1], color: '#0066d4' },
        { protocol: 'HTTP', latency: results[2], color: '#ffa500' },
        { protocol: 'HTTPS', latency: results[3], color: '#9f009f' },
      ].filter(item => item.latency !== null);

      if (newChartData.length > 0) {
        setChartData(newChartData);
      } else {
        setError('No data available for the selected time period.');
      }
    } catch (err) {
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [fetchMsFromInfluxDB]);

  useEffect(() => {
    fetchDataAndUpdate();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(fetchDataAndUpdate, refreshInterval);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [fetchDataAndUpdate, refreshFrequency]);

  // if (isLoading) {
  //   return (
  //     <Box className="network-status-summary" display="flex" justifyContent="center" alignItems="center" height={200}>
  //       <CircularProgress />
  //     </Box>
  //   );
  // }

  if (error) {
    return (
      <Box className="network-status-summary" display="flex" justifyContent="center" alignItems="center" height={200}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (chartData.length === 0) {
    return (
      <Box className="network-status-summary" display="flex" justifyContent="center" alignItems="center" height={200}>
        <Typography>No data available</Typography>
      </Box>
    );
  }

  return (
    <Box className="network-status-summary">
      <Paper className="network-status-summary paper">
        <Typography variant="h5">{t('latencyAppProto')}</Typography>
        <div style={{ height: 200 }}>
          <ResponsiveBar
            data={chartData}
            keys={['latency']}
            indexBy="protocol"
            margin={{ top: 20, right: 60, bottom: 40, left: 60 }}
            padding={0.3}
            layout="vertical"
            colors={({ data }) => data.color}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Protocol',
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Latency (ms)',
              legendPosition: 'middle',
              legendOffset: -40,
            }}
            labelSkipWidth={3}
            labelSkipHeight={3}
            labelTextColor="black"
            theme={{
              axis: {
                ticks: {
                  text: {
                    fill: '#A3A3A3',
                    fontSize: 14,
                  },
                },
                legend: {
                  text: {
                    fill: '#A3A3A3',
                    fontSize: 14,
                  },
                },
              },
              tooltip: {
                container: {
                  background: '#212446',
                  color: 'white',
                  border: '2px solid rgba(233, 244, 244, 0.2)',
                },
              },
            }}
            animate={true}
            motionStiffness={10}
            motionDamping={4}
          />
        </div>
      </Paper>
    </Box>
  );
};

export default ApplicationBarChart;